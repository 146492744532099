import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Home' },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: 'About' },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { title: 'Contact' },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  // {
  //   path: '/designs',
  //   name: 'Designs',
  //   meta: { title: 'Designs' },
  //   component: () => import(/* webpackChunkName: "Designs" */ '../views/Designs.vue')
  // },
  {
    path: '/my-scotland-wedding',
    name: 'My Scotland Wedding',
    meta: { title: 'My Scotland Wedding' },
    component: () => import(/* webpackChunkName: "My Scotland Wedding" */ '../views/Scotland.vue')
  },
  {
    path: '/walmart-grocery',
    name: 'Walmart Grocery',
    meta: { title: 'Walmart Grocery' },
    component: () => import(/* webpackChunkName: "Walmart Grocery" */ '../views/Walmart.vue')
  },
  {
    path: '/seneca-light-and-water',
    name: 'Seneca Light & Water',
    meta: { title: 'Seneca Light & Water' },
    component: () => import(/* webpackChunkName: "Seneca Light & Water" */ '../views/SenecaLightWater.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = `${to.meta.title} | Keyla Jones`;
  });
});

export default router
