<template>
  <div id="app">
    <Header/>
    <router-view />
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
body {
  margin: 0;
}
h1, h2, h3, h4, h5, h6, p, span, a, button, input, textarea, li {
  margin: 0;
  padding: 0;
  font-family: robReg;
  font-weight: normal;
}

.noSelect, a, button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

.flex-w-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-start-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-start-start-col {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.flex-start-center-col {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.flex-start-around {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.flex-start-around-col {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
}
.flex-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-start-between-col {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.flex-end-center {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.flex-end-center-col {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-center-start-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-center-end-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-between-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-center-around-col {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.flex-center-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.flex-center-evenly-col {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.grid { display: grid; }
.grid-2-col { grid-template-columns: 1fr 1fr; }
.grid-4-col { grid-template-columns: 1fr 1fr 1fr 1fr; } 
.grid-4-300-col { grid-template-columns: repeat(auto-fill, 300px); }

.block { display: block; }
.inline-block { display: inline-block; }
.flex { display: flex; }
.flex-col { flex-direction: column; }
.flex-wrap { flex-wrap: wrap; }
.items-center { align-items: center; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.justify-evenly { justify-content: space-evenly; }

.gap-10 { gap: 10px; }
.gap-20 { gap: 20px; }
.gap-30 { gap: 30px; }
.gap-40 { gap: 40px; }
.gap-50 { gap: 50px; }
.gap-60 { gap: 60px; }
.gap-70 { gap: 70px; }
.gap-80 { gap: 80px; }
.gap-90 { gap: 90px; }
.gap-100 { gap: 100px; }
.gap-120 { gap: 120px; }

.m-auto { margin: auto; }
.m-0 { margin: 0; }
.m-5 { margin: 5px; }
.m-10 { margin: 10px; }
.m-15 { margin: 15px; }
.m-20 { margin: 20px; }
.m-25 { margin: 25px; }

.mt-0 { margin-top: 0; }
.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }
.mt-60 { margin-top: 60px; }
.mt-120 { margin-top: 120px; }
.mt-140 { margin-top: 140px; }

.mr-0 { margin-right: 0; }
.mr-3 { margin-right: 3px; }
.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; }
.mr-35 { margin-right: 35px; }
.mr-60 { margin-right: 60px; }

.mb-0 { margin-bottom: 0; }
.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }
.mb-125 { margin-bottom: 125px; }

.mb-o-10 { margin: 0 0 10px 0; }
.mb-o-25 { margin: 0 0 25px 0; }

.ml-0 { margin-left: 0; }
.ml-a { margin-left: auto; }
.ml-3 { margin-left: 3px; }
.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-60 { margin-left: 60px; }

.m-y-0 { margin-top: 0; margin-bottom: 0; }
.m-y-5 { margin-top: 5px; margin-bottom: 5px; }
.m-y-10 { margin-top: 10px; margin-bottom: 10px; }
.m-y-10p { margin-top: 10%; margin-bottom: 10%; }
.m-y-15 { margin-top: 15px; margin-bottom: 15px; }
.m-y-20 { margin-top: 20px; margin-bottom: 20px; }
.m-y-25 { margin-top: 25px; margin-bottom: 25px; }
.m-y-30 { margin-top: 30px; margin-bottom: 30px; }
.m-y-35 { margin-top: 35px; margin-bottom: 35px; }
.m-y-40 { margin-top: 40px; margin-bottom: 40px; }
.m-y-50 { margin-top: 50px; margin-bottom: 50px; }
.m-y-75 { margin-top: 75px; margin-bottom: 75px; }
.m-y-100 { margin-top: 100px; margin-bottom: 100px; }

.m-x-auto { margin-left: auto; margin-right: auto; }
.m-x-0 { margin-left: 0; margin-right: 0; }
.m-x-5 { margin-left: 5px; margin-right: 5px; }
.m-x-10 { margin-left: 10px; margin-right: 10px; }
.m-x-10p { margin-left: 10%; margin-right: 10%; }
.m-x-15p { margin-left: 15%; margin-right: 15%; }
.m-x-20p { margin-left: 20%; margin-right: 20%; }
.m-x-25p { margin-left: 25%; margin-right: 25%; }
.m-x-30p { margin-left: 30%; margin-right: 30%; }
.m-x-15 { margin-left: 15px; margin-right: 15px; }
.m-x-20 { margin-left: 20px; margin-right: 20px; }
.m-x-25 { margin-left: 25px; margin-right: 25px; }

.p-0 { padding: 0; }
.p-5 { padding: 5px; }
.p-10 { padding: 10px; }
.p-15 { padding: 15px; }
.p-20 { padding: 20px; }
.p-25 { padding: 25px; }
.p-35 { padding: 35px; }
.p-50 { padding: 50px; }

.pt-0 { padding-top: 0; }
.pt-5 { padding-top: 5px; }
.pt-10 { padding-top: 10px; }
.pt-15 { padding-top: 15px; }
.pt-20 { padding-top: 20px; }
.pt-25 { padding-top: 25px; }
.pt-40 { padding-top: 40px; }

.pr-0 { padding-right: ; }
.pr-5 { padding-right: 5px; }
.pr-10 { padding-right: 10px; }
.pr-15 { padding-right: 15px; }
.pr-20 { padding-right: 20px; }
.pr-25 { padding-right: 25px; }
.pr-50 { padding-right: 50px; }

.pb-0 { padding-bottom: 0; }
.pb-5 { padding-bottom: 5px; }
.pb-10 { padding-bottom: 10px; }
.pb-15 { padding-bottom: 15px; }
.pb-20 { padding-bottom: 20px; }
.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }

.pl-0 { padding-left: 0; }
.pl-5 { padding-left: 5px; }
.pl-10 { padding-left: 10px; }
.pl-15 { padding-left: 15px; }
.pl-20 { padding-left: 20px; }
.pl-25 { padding-left: 25px; }

.p-y-0 { padding-top: 0; padding-bottom: 0; }
.p-y-5 { padding-top: 5px; padding-bottom: 5px; }
.p-y-10 { padding-top: 10px; padding-bottom: 10px; }
.p-y-10p { padding-top: 10%; padding-bottom: 10%; }
.p-y-15 { padding-top: 15px; padding-bottom: 15px; }
.p-y-20 { padding-top: 20px; padding-bottom: 20px; }
.p-y-25 { padding-top: 25px; padding-bottom: 25px; }
.p-y-50 { padding-top: 50px; padding-bottom: 50px; }

.p-x-0 { padding-left: 0; padding-right: 0; }
.p-x-5 { padding-left: 5px; padding-right: 5px; }
.p-x-10 { padding-left: 10px; padding-right: 10px; }
.p-x-10p { padding-left: 10%; padding-right: 10%; }
.p-x-15 { padding-left: 15px; padding-right: 15px; }
.p-x-20 { padding-left: 20px; padding-right: 20px; }
.p-x-25 { padding-left: 25px; padding-right: 25px; }
.p-x-30 { padding-left: 30px; padding-right: 30px; }
.p-x-50 { padding-left: 50px; padding-right: 50px; }
.p-x-75 { padding-left: 75px; padding-right: 75px; }
.p-x-115 { padding-left: 115px; padding-right: 115px; }

.h-full { height: 100%; }
.h-600 { height: 600px; }
.h-500 { height: 500px; }
.h-400 { height: 400px; }
.h-350 { height: 350px; }
.h-340 { height: 340px; }
.h-300 { height: 300px; }
.h-250 { height: 250px; }
.h-175 { height: 175px; }
.h-150 { height: 150px; }
.h-120 { height: 120px; }
.h-100 { height: 100px; }
.h-60 { height: 60px; }
.h-50 { height: 50px; }
.h-40 { height: 40px; }
.h-35\! { height: 35px !important; }
.h-0 { height: 0; }

.w-full { width: 100%; }
.w-half { width: 50%; }
.w-650 { width: 650px; }
.w-500 { width: 500px; }
.w-400 { width: 400px; }
.w-350 { width: 350px; }
.w-300 { width: 300px; }
.w-250 { width: 250px; }
.w-200 { width: 200px; }
.w-176 { width: 176px; }
.w-150 { width: 150px; }
.w-110 { width: 110px; }
.w-100 { width: 100px; }
.w-75p { width: 75%; }
.w-75 { width: 75px; }
.w-50 { width: 50px; }
.w-60p { width: 60%; }
.w-50p { width: 50%; }
.w-51p { width: 50.5%; }
.w-49p { width: 49.5%; }
.w-40p { width: 40%; }
.w-44 { width: 44px; }
.w-40 { width: 40px; }
.w-35 { width: 35px; }
.w-35\! { width: 35px !important; }
.w-25 { width: 25px; }
.w-10 { width: 10px; }
.w-0 { width: 0; }

.m-h-700 { max-height: 700px; }
.m-h-650 { max-height: 650px; }
.m-h-500 { max-height: 500px; }
.m-h-450 { max-height: 450px; }
.m-h-385 { max-height: 385px; }
.m-h-300 { max-height: 300px; }
.m-h-285 { max-height: 285px; }
.m-h-241 { max-height: 241px; }

.min-h-150 { min-height: 150px; }

.m-w-1200 { max-width: 1200px; }
.m-w-1000 { max-width: 1000px; }
.m-w-750 { max-width: 750px; }
.m-w-700 { max-width: 700px; }
.m-w-650 { max-width: 650px; }
.m-w-600 { max-width: 600px; }
.m-w-571 { max-width: 571px; }
.m-w-550 { max-width: 550px; }
.m-w-500 { max-width: 500px; }
.m-w-480 { max-width: 480px; }
.m-w-450 { max-width: 450px; }
.m-w-400 { max-width: 400px; }
.m-w-375 { max-width: 375px; }
.m-w-350 { max-width: 350px; }
.m-w-300 { max-width: 300px; }
.m-w-250 { max-width: 250px; }
.m-w-200 { max-width: 200px; }
.m-w-175 { max-width: 175px; }
.m-w-150 { max-width: 150px; }

.pos-rel { position: relative; }
.pos-abs { position: absolute; }

.color-white { color: white; }
.color-black { color: black; }
.color-nav-gray { color: #858585; }
.color-gray { color: #6F6F6F; }
.color-blue { color: #209dbc; }
.hover\:color-gray:hover { color: #6F6F6F; }

.bg-white { background-color: white; }
.bg-black-60p { background-color: rgba(0,0,0,0.6); }
.bg-footer-gray { background-color: #272727; }
.bg-gray { background-color: #6F6F6F; }
.bg-blue { background-color: #209dbc; }

.bg-blue1 { background-color: #006494; }
.bg-blue2 { background-color: #3383a9; }
.bg-blue3 { background-color: #66a2bf; }
.bg-blue4 { background-color: #99c1d4; }
.bg-blue5 { background-color: #b2d0df; }

.bg-wal-1 { background-color: #99C6F1; }
.bg-wal-2 { background-color: #80B8ED; }
.bg-wal-3 { background-color: #4D9CE6; }
.bg-wal-4 { background-color: #2686E1; }
.bg-wal-5 { background-color: #0071DC; }

.text-center { text-align: center; }
.text-uppercase { text-transform: uppercase; }
.no-underline { text-decoration: none; }
.no-resize { resize: none; }

.opacity-0 { opacity: 0; }
.opacity-1 { opacity: 1; }
.ease-5 { transition: .5s ease; }
.ease-4 { transition: .4s ease; }
.ease-3 { transition: .3s ease; }
.ease-2 { transition: .2s ease; }
.ease-1 { transition: .1s ease; }

.hover-reveal:hover .opacity-0 {
  opacity: 1;
}

.border-white-faint { border: 1px solid rgba(255, 255, 255, 0.5); }
.border-white-top { border-top: 1px solid #eaeaea; }
.border-white-bottom { border-bottom: 1px solid #eaeaea; }
.border-gray { border: 1px solid #6f6f6f; }
.border-top-gray { border: 0; border-top: 2px solid #6f6f6f; }
.border-blue { border: 3px solid rgb(32, 157, 188); }
.border-blue-1 { border: 1px solid rgb(32, 157, 188); }
.border-blue-tb { border-top: 10px solid rgba(32, 157, 188, 0.5);; border-bottom: 10px solid rgba(32, 157, 188, 0.5); }
.border-black-1 { border: 1px solid black; }

.radius-100p { border-radius: 100%; }
.radius-50p { border-radius: 50%; }
.radius-100 { border-radius: 100px; }
.radius-30 { border-radius: 30px; }
.radius-25 { border-radius: 25px; }
.radius-l-25 { border-top-left-radius: 25px; border-bottom-left-radius: 25px; }
.radius-15 { border-radius: 15px; }
.radius-10 { border-radius: 10px; }
.radius-5 { border-radius: 5px; }

.border-box { box-sizing: border-box; }

.small-shadow { filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)); }
.medium-shadow { filter: drop-shadow(-5px 5px 10px rgba(0, 0, 0, 0.1)); }
.small-all-shadow { filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2)); }
.box-shadow { box-shadow: 0px 4px 4px 0 rgb(0 0 0 / 25%); }

.font-robReg { font-family: robReg; }
.font-robBold { font-family: robBold; }
.font-robItalic { font-family: robItalic; }
.font-libItalic { font-family: libItalic; }
.font-robMed { font-family: robMed; }
.font-barReg { font-family: barReg; }
.font-barBold { font-family: barBold; }
.font-barBold\! { font-family: barBold !important; }
.font-barBoldItalic { font-family: barBoldItalic !important; }

.font-100 { font-size: 100px; }
.font-74 { font-size: 74px; }
.font-64 { font-size: 64px; }
.font-48 { font-size: 48px; }
.font-40 { font-size: 40px; }
.font-38 { font-size: 38px; }
.font-36 { font-size: 36px; }
.font-32 { font-size: 32px; }
.font-24 { font-size: 24px; }
.font-20 { font-size: 20px; }
.font-18 { font-size: 18px; }
.font-16 { font-size: 16px; }
.font-14 { font-size: 14px; }
.font-12 { font-size: 12px; }
.font-10 { font-size: 10px; }

.word-break { word-break: break-all; }

.overflow-hidden { overflow: hidden; }
.overflow-y-scroll { overflow-y: scroll; }
.overflow-x-hidden { overflow-x: hidden; }

.cursor-pointer { cursor: pointer; }
.no-pointer { pointer-events: none; }

.desktopHide { display: none; }
.hide { display: none; }

.object-cover { object-fit: cover; }
.object-contain { object-fit: contain; }
.object-none { object-fit: none; }

@media (max-width: 1100px) {
  .md\:gap-60 { gap: 60px; }
  .md\:grid-2-col { grid-template-columns: 1fr 1fr; }
}
@media (max-width: 900px) {
  .md\:h-30 { height: 30px; }
  .md\:grid-2-col { grid-template-columns: 1fr 1fr; }
}
@media (max-width: 768px) {
  .mobileHide { display: none; }
  .desktopHide { display: block; }

  .mb\:m-0 { margin: 0; }
  .mb\:m-5 { margin: 5px; }
  .mb\:m-10 { margin: 10px; }
  .mb\:m-15 { margin: 15px; }
  .mb\:m-20 { margin: 20px; }
  .mb\:m-25 { margin: 25px !important; }

  .mb\:mt-0 { margin-top: 0; }
  .mb\:mt-5 { margin-top: 5px; }
  .mb\:mt-10 { margin-top: 10px; }
  .mb\:mt-15 { margin-top: 15px; }
  .mb\:mt-20 { margin-top: 20px; }
  .mb\:mt-25 { margin-top: 25px; }
  .mb\:mt-40 { margin-top: 40px; }

  .mb\:mr-0 { margin-right: 0; }
  .mb\:mr-3 { margin-right: 3px; }
  .mb\:mr-5 { margin-right: 5px; }
  .mb\:mr-10 { margin-right: 10px; }
  .mb\:mr-15 { margin-right: 15px; }
  .mb\:mr-20 { margin-right: 20px; }

  .mb\:mb-0 { margin-bottom: 0; }
  .mb\:mb-5 { margin-bottom: 5px; }
  .mb\:mb-10 { margin-bottom: 10px; }
  .mb\:mb-15 { margin-bottom: 15px; }
  .mb\:mb-20 { margin-bottom: 20px; }
  .mb\:mb-25 { margin-bottom: 25px; }
  .mb\:mb-30 { margin-bottom: 30px; }
  .mb\:mb-50 { margin-bottom: 50px; }

  .mb\:mb-o-10 { margin: 0 0 10px 0; }
  .mb\:mb-o-25 { margin: 0 0 25px 0; }

  .mb\:ml-0 { margin-left: 0; }
  .mb\:ml-a { margin-left: auto; }
  .mb\:ml-3 { margin-left: 3px; }
  .mb\:ml-5 { margin-left: 5px; }
  .mb\:ml-10 { margin-left: 10px; }
  .mb\:ml-15 { margin-left: 15px; }
  .mb\:ml-20 { margin-left: 20px; }

  .mb\:m-y-0 { margin-top: 0; margin-bottom: 0; }
  .mb\:m-y-5 { margin-top: 5px; margin-bottom: 5px; }
  .mb\:m-y-10 { margin-top: 10px; margin-bottom: 10px; }
  .mb\:m-y-10p { margin-top: 10%; margin-bottom: 10%; }
  .mb\:m-y-15 { margin-top: 15px; margin-bottom: 15px; }
  .mb\:m-y-20 { margin-top: 20px; margin-bottom: 20px; }
  .mb\:m-y-25 { margin-top: 25px; margin-bottom: 25px; }
  .mb\:m-y-35 { margin-top: 35px; margin-bottom: 35px; }
  .mb\:m-y-75 { margin-top: 75px; margin-bottom: 75px; }
  .mb\:m-y-100 { margin-top: 100px; margin-bottom: 100px; }

  .mb\:m-x-0 { margin-left: 0; margin-right: 0; }
  .mb\:m-x-5 { margin-left: 5px; margin-right: 5px; }
  .mb\:m-x-10 { margin-left: 10px; margin-right: 10px; }
  .mb\:m-x-10p { margin-left: 10%; margin-right: 10%; }
  .mb\:m-x-15 { margin-left: 15px; margin-right: 15px; }
  .mb\:m-x-20 { margin-left: 20px; margin-right: 20px; }
  .mb\:m-x-25 { margin-left: 25px; margin-right: 25px; }

  .mb\:p-0 { padding: 0; }
  .mb\:p-5 { padding: 5px; }
  .mb\:p-10 { padding: 10px; }
  .mb\:p-15 { padding: 15px; }
  .mb\:p-20 { padding: 20px; }
  .mb\:p-25 { padding: 25px; }
  .mb\:p-50 { padding: 50px; }

  .mb\:pt-0 { padding-top: 0; }
  .mb\:pt-5 { padding-top: 5px; }
  .mb\:pt-10 { padding-top: 10px; }
  .mb\:pt-15 { padding-top: 15px; }
  .mb\:pt-20 { padding-top: 20px; }
  .mb\:pt-25 { padding-top: 25px; }

  .mb\:pr-0 { padding-right: ; }
  .mb\:pr-5 { padding-right: 5px; }
  .mb\:pr-10 { padding-right: 10px; }
  .mb\:pr-15 { padding-right: 15px; }
  .mb\:pr-20 { padding-right: 20px; }

  .mb\:pb-0 { padding-bottom: 0; }
  .mb\:pb-5 { padding-bottom: 5px; }
  .mb\:pb-10 { padding-bottom: 10px; }
  .mb\:pb-15 { padding-bottom: 15px; }
  .mb\:pb-20 { padding-bottom: 20px; }
  .mb\:pb-50 { padding-bottom: 50px; }

  .mb\:pl-0 { padding-left: 0; }
  .mb\:pl-5 { padding-left: 5px; }
  .mb\:pl-10 { padding-left: 10px; }
  .mb\:pl-15 { padding-left: 15px; }
  .mb\:pl-20 { padding-left: 20px; }

  .mb\:p-y-0 { padding-top: 0; padding-bottom: 0; }
  .mb\:p-y-5 { padding-top: 5px; padding-bottom: 5px; }
  .mb\:p-y-10 { padding-top: 10px; padding-bottom: 10px; }
  .mb\:p-y-10p { padding-top: 10%; padding-bottom: 10%; }
  .mb\:p-y-15 { padding-top: 15px; padding-bottom: 15px; }
  .mb\:p-y-20 { padding-top: 20px; padding-bottom: 20px; }
  .mb\:p-y-25 { padding-top: 25px; padding-bottom: 25px; }
  .mb\:p-y-50 { padding-top: 50px; padding-bottom: 50px; }

  .mb\:p-x-0 { padding-left: 0; padding-right: 0; }
  .mb\:p-x-5 { padding-left: 5px; padding-right: 5px; }
  .mb\:p-x-10 { padding-left: 10px; padding-right: 10px; }
  .mb\:p-x-10p { padding-left: 10%; padding-right: 10%; }
  .mb\:p-x-15 { padding-left: 15px; padding-right: 15px; }
  .mb\:p-x-20 { padding-left: 20px; padding-right: 20px; }
  .mb\:p-x-25 { padding-left: 25px; padding-right: 25px; }
  .mb\:p-x-30 { padding-left: 30px; padding-right: 30px; }
  .mb\:p-x-50 { padding-left: 50px; padding-right: 50px; }
  
  .mb\:text-center { text-align: center; }
  
  .mb\:w-auto { width: auto !important; }
  .mb\:w-full { width: 100%; }
  .mb\:w-full\! { width: 100% !important; }
  .mb\:h-auto { height: auto; }
  .mb\:m-h-reset { max-height: none; }
  .mb\:h-reset { height: auto; }
  .mb\:m-w-reset { max-width: none; }
  .mb\:h-full { height: 100% !important; }
  .mb\:h-500\! { height: 500px !important; }
  .mb\:h-500 { height: 500px; }
  .mb\:h-350 { height: 350px; }
  .mb\:h-20 { height: 20px; }
  .mb\:w-40 { width: 40px; }
  .mb\:w-80p { width: 80%; }

  .mb\:flex-col { flex-direction: column; }
  .mb\:flex-col-reverse { flex-direction: column-reverse; }
  .mb\:justify-center { justify-content: center; }
  .mb\:items-center { align-items: center; }

  .mb\:gap-10 { gap: 10px; }
  .mb\:gap-20 { gap: 20px; }
  .mb\:gap-30 { gap: 30px; }
  .mb\:gap-40 { gap: 40px; }
  .mb\:gap-50 { gap: 50px; }
  .mb\:gap-60 { gap: 60px; }
  .mb\:gap-70 { gap: 70px; }
  .mb\:gap-80 { gap: 80px; }
  .mb\:gap-90 { gap: 90px; }
  .mb\:gap-100 { gap: 100px; }

  .mb\:grid-1-col { grid-template-columns: 1fr; }

  .mb\:font-100 { font-size: 100px; }
  .mb\:font-74 { font-size: 74px; }
  .mb\:font-64 { font-size: 64px; }
  .mb\:font-48 { font-size: 48px; }
  .mb\:font-40 { font-size: 40px; }
  .mb\:font-38 { font-size: 38px; }
  .mb\:font-36 { font-size: 36px; }
  .mb\:font-32 { font-size: 32px; }
  .mb\:font-24 { font-size: 24px; }
  .mb\:font-20 { font-size: 20px; }
  .mb\:font-18 { font-size: 18px; }
  .mb\:font-16 { font-size: 16px; }
  .mb\:font-14 { font-size: 14px; }
  .mb\:font-12 { font-size: 12px; }
  .mb\:font-10 { font-size: 10px; }
}

@font-face {
  font-family: robReg;
  src: url(./assets/fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: robBold;
  src: url(./assets/fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: robItalic;
  src: url(./assets/fonts/Roboto-Italic.ttf);
}
@font-face {
  font-family: libItalic;
  src: url(./assets/fonts/LibreBaskerville-Italic.ttf);
}
@font-face {
  font-family: robMed;
  src: url(./assets/fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: barReg;
  src: url(./assets/fonts/Barlow-Regular.ttf);
}
@font-face {
  font-family: barBold;
  src: url(./assets/fonts/Barlow-Bold.ttf);
}
@font-face {
  font-family: barBoldItalic;
  src: url(./assets/fonts/Barlow-BoldItalic.ttf);
}
</style>
