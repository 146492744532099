<template>
  <div class="flex-center-col bg-footer-gray w-full h-120">
    <div>
      <router-link :class="`no-underline color-nav-gray font-robBold text-uppercase font-12 ${checkLast(index, links.length) ? '' : 'mr-10'}`" v-for="(link, index) in links" :key="link.id" :to="link.link">{{ link.text }}</router-link>
    </div>
    <h1 class="font-24 font-barBold color-white mt-10 mb-15">KEYLA JONES</h1>
    <p class="color-gray font-robItalic font-10">Copyright &copy; 2022 KEYLA JONES. All Rights Reserved.</p>
  </div>
</template>

<script>
import links from '@/assets/scripts/links.js'

export default {
  data() {
    return {
      links
    }
  },
  methods: {
    checkLast(index, length) {
      return index === length - 1;
    }
  }
}
</script>

<style scoped>
.router-link-exact-active {
  color: #6F6F6F;
}
</style>
