<template>
  <div class="flex-center-between h-120 p-x-115 mb:p-x-25">
    <router-link to="/" class="text-uppercase no-underline color-black font-24 font-barBold!">Keyla Jones</router-link>
    <div>
      <router-link active-exact-class="font-robMed" v-for="(link, index) in links" :class="`hover:color-gray font-14 mobileHide no-underline color-nav-gray text-uppercase ${checkLast(index, links.length) ? '' : 'mr-10'}`" :key="link.id" :to="link.link">{{ link.text }}</router-link>
      <img @click="mobileHeader = true" class="desktopHide w-35" src="@/assets/images/menu.svg" alt="Menu">
    </div>
    <div class="w-full h-full ptb-50 bg-white header flex-center flex-col" v-show="mobileHeader">
      <img @click="mobileHeader = false" class="w-25 mb-125" src="@/assets/images/cross.svg" alt="Close">
      <div class="flex-center flex-col mobileLinks gap-50">
        <router-link @click.native="mobileHeader = false" v-for="link in links" v-bind:key="link.id" :to="link.link" class="no-underline color-black text-uppercase" >{{ link.text }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import links from '@/assets/scripts/links.js'

export default {
  data() {
    return {
      links,
      mobileHeader: false,
    }
  },
  methods: {
    checkLast(index, length) {
      return index === length - 1;
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  overflow-x: hidden;
}
.router-link-exact-active {
  font-family: robBold;
  color: black;
}
</style>
